import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "next-i18next";
import Image from "next/image";

import { getPosts } from "ab-api-queries";
import Text from "ab-text";
import Link from "ab-link";

import "./ab-noticias-cross.scss";

const NoticiasCross = ({ classname, title, text, link, i18n }) => {
  // console.log("notiicascross", language);
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    getPosts({
      first: 2,
      where: {
        language: i18n.language == "ES" ? "ES" : "EN",
      },
    }).then((res) => {
      // console.log("NoticiasCross -res", res);
      setPosts(res.posts?.nodes);
    });
  }, []);
  const { t } = useTranslation("common", { i18n });
  // console.log("NoticiasCross");
  return (
    <div className={`ab-noticias-cross ${classname || ""}`}>
      <div className="left">
        <Text element="h2" animated>
          {title}
        </Text>
        <br />
        <Text>{text}</Text>
        <Link prefetch={false} text={link?.title} href={link?.url} />
      </div>
      <div className="right">
        {posts.map((itm, index) => (
          <div className="noticias-tile" key={`noticias-cross-${index}`}>
            {/* <div
              className="image"
              style={{
                backgroundImage: `url(${itm?.featuredImage?.node?.mediaItemUrl})`,
              }}
            /> */}
            <Image
              src={itm?.featuredImage?.node?.mediaItemUrl || '/images/404.png'}
              width={381}
              height={381}
              objectFit="cover"
              alt="cross"
            />

            <Text element="span" className="title">
              {dayjs(itm?.date).format("MMMM DD, YYYY")}
            </Text>
            <Text element="h4">{itm?.title}</Text>
            <Text>{itm?.contenidoFlexible?.modulos?.[0]?.excerpt}</Text>
            <Link
              prefetch={false}
              text={t("leer-mas")}
              href={`${t("/blog")}/${itm?.slug}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default NoticiasCross;
